<template>
  <Dialog v-model:visible="isShow" :style="{width: '500px'}" header="归档" :modal="true" class="p-fluid"
          @hide="isShow=false">
    <form @submit.prevent="handleSubmit()" class="p-fluid" style="margin-top: 32px">
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="justify-content: flex-end">
          归档周期类别:
        </label>
        <div class="p-col">
          <RadioButton id="byYear" name="city" value="year" v-model="form.type"  style="margin-left:20px;"/>
          <label for="byYear" style="margin-left:10px;color:rgba(0, 0, 0, 0.45);">按年</label>

<!--          <RadioButton id="bySeason" name="city" value="season" v-model="form.type"  style="margin-left:20px;"/>-->
<!--          <label for="bySeason" style="margin-left:10px;color:rgba(0, 0, 0, 0.45);">按季度（冬春、夏秋）</label>-->

<!--          <RadioButton id="byOther" name="city" value="other" v-model="form.type"  style="margin-left:20px;"/>-->
<!--          <label for="byOther" style="margin-left:10px;color:rgba(0, 0, 0, 0.45);">其它</label>-->
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button label="取消" class="p-button-outlined" @click="isShow=false"/>
        <Button style="margin: 0" type="submit" label="提交"/>
      </div>
    </form>
  </Dialog>
  <Toast/>
</template>

<script>

export default {
    data(){
      return {
        isShow: false,
        isOldPasswordError: false,
        form: {
          type: null,
          $submitted: false
        },
        rows: null,
        parentObj: null
      }
    },
  mounted() {
  },
  methods: {

      show(rows, parentObj) {
        this.form.type="year"
        this.rows = rows;
        this.parentObj = parentObj;
        this.isShow = true;
      },
      handleSubmit() {
        this.form.$submitted = true;

        if(!this.form.type){
          this.$toast.add({severity: 'warn', summary: '错误', detail: '请选择归档类别！', life: 3000});
          return
        }

        this.$confirm.require({
          header: '确认',
          message: '确认要归档所选的项目吗？',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: '确认',
          rejectLabel: '取消',
          accept: () => {

            let ids = "";
            for (let i in this.rows) {
              ids += this.rows[i].id + ",";
            }
            ids = ids.substring(0, ids.length - 1);
            this.$http('/project/archive', 'get', {ids: ids, type: this.form.type}, ()=> {
              this.isShow = false;
              this.$toast.add({severity: 'success', summary: '成功', detail: '所选归档成功！', life: 3000});
              this.parentObj.loadTable();
            })
          }
        });
      }
    }
}
</script>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer{
  padding: 0;
}
::v-deep(.p-radiobutton .p-radiobutton-box.p-highlight){
  background: #12A25D;
  border-color: #12A25D;
}
::v-deep(.p-radiobutton .p-radiobutton-box.p-highlight:hover){
  background: #12A25D;
  border-color: #12A25D;
}
::v-deep(.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover){
  border-color: #12A25D;
}
</style>
