<template>
  <Dialog v-model:visible="isShow" :style="{width: '750px',height:'480px'}" :header="'组员：'+dataTable.selected.length+' 人 '+(currLeader.name?'，组长：'+currLeader.name:'，无组长')" :modal="true" class="p-fluid"
    @hide="isShow=false;name=null">
    <InputText placeholder="请输入用户姓名" v-model='name' style="width:30%;z-index:98" @keypress="$event.keyCode==13?getData():{}"></InputText>

    <span class="pi pi-search p-button-icon" style="position:relative;left:-30px;cursor:pointer;z-index:99" @keyup.enter.native="getData()"></span>

    <DataTable style="height:400px;margin-top:20px" dataKey="id" :rowHover="true" v-model:selection="dataTable.selected" responsiveLayout="scroll" :value="dataTable.gridData" :lazy="true"
      @page="onPage($event)" :totalRecords="dataTable.totalRecords" :paginator="true" :rows="5" paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
      :rowsPerPageOptions="[5,20,50,100]" currentPageReportTemplate="共 {totalRecords} 条">
      <template #empty>
        没有发现数据。
      </template>
      <Column selectionMode="multiple" style="width: 1rem"></Column>
      <Column field="name" header="用户姓名"></Column>
      <Column field="telephone" header="手机号码"></Column>
      <Column field="team" header="所属团队"></Column>
      <Column :exportable="false" header="团队管理员">
        <template #body="slotProps">
          <RadioButton name="leader" :value="slotProps.data" v-model="currLeader" />
          <label style="margin-left:6px;">设为组长</label>
        </template>
      </Column>
      <template #paginatorLeft></template>
      <template #paginatorRight>
        <div class="p-grid" style="padding-top:5px;display: flex;align-items: center;">
          <label class="p-col" style="width:70px;display: flex">跳至&nbsp;&nbsp;</label>
          <InputNumber class="p-col" @keypress="jumpPage()" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;" v-model="dataTable.jumpPageNum" />
          <label style="margin-top:6px;" class="p-col">&nbsp;&nbsp;页</label>
        </div>
      </template>
    </DataTable>
    <template #footer>
      <Button label="取消" class="p-button-outlined" @click="isShow=false" />
      <Button label="确定" @click="submit()" />
    </template>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      isShow: false,
      dataTable: {
        gridData: [],
        selected: [],
        queryParams: {
          nameOrTelephone: null,
        },
        lazyParams: {
          page: 0,
        },
        loading: true,
        totalRecords: 0,
        jumpPageNum: 1,
      },
      currProjRows: null,
      parentObj: null,
      currLeader: {},
    };
  },
  methods: {
    submit() {
      let me = this;
      if (!this.dataTable.selected || this.dataTable.selected.length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "您没有选择人员！",
          life: 3000,
        });
        return;
      }

      if (Object.keys(this.currLeader).length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请选择一名组长！",
          life: 3000,
        });
        return;
      }

      let parmas = {
        leaderId: this.currLeader.id,
      };

      // 生成projIds
      let projIds = "";
      for (let i in this.currProjRows) {
        projIds += this.currProjRows[i].id + ",";
      }
      projIds = projIds.substring(0, projIds.length - 1);
      parmas["projIds"] = projIds;

      // 生成userIds
      let userIds = "";
      for (let i in me.dataTable.selected) {
        userIds += me.dataTable.selected[i].id + ",";
      }
      userIds = userIds.substring(0, userIds.length - 1);
      parmas["userIds"] = userIds;

      this.$http("/proj_user_ref/update", "get", parmas, function () {
        me.isShow = false;
        me.$toast.add({
          severity: "success",
          summary: "成功",
          detail: "所属用户设置成功！",
          life: 3000,
        });
        me.parentObj.loadTable(me.parentObj.dataTable.lastParams);
      });
    },
    show(parentObj, rows) {
      this.dataTable.selected = [];
      this.dataTable.lazyParams.page = 0;
      this.currProjRows = rows;
      this.isShow = true;
      this.parentObj = parentObj;
      this.currLeader = {};

      this.loadTable();
    },
    jumpPage() {
      if (this.dataTable.jumpPageNum > 0 && event.keyCode == 13) {
        this.dataTable.lazyParams.page = this.dataTable.jumpPageNum - 1;
        this.loadTable();
      }
    },
    /**
     * 加载表格数据
     */
    loadTable(params) {
      let me = this;
      me.loading = true;

      let targetParmas = {
        proId: this.currProjRows[0].id,
        current: this.dataTable.lazyParams.page + 1,
        size: this.dataTable.lazyParams.rows,
      };
      if (params) {
        Object.assign(targetParmas, params);
      }
      this.getData();
    },
    getData() {
      let me = this;
      this.$http(
        "/user/projectUserList",
        "get",
        {
          proId: this.currProjRows[0].id,
          current: this.dataTable.lazyParams.page + 1,
          size: this.dataTable.lazyParams.rows,
          name: this.name,
        },
        (response) => {
          console.log(response.data);
          me.dataTable.gridData = response.data.records;
          me.dataTable.totalRecords = response.data.total;
          me.dataTable.loading = false;

          // 回显用户
          // 仅需在首次加载时回显
          if (Object.keys(me.dataTable.selected).length == 0) {
            let userlist = me.currProjRows[0].userlist;
            let templist = [];

            if (userlist) {
              for (let i in userlist) {
                let user = userlist[i];
                templist.push({ id: user.id });

                if (user.is_leader == 1) {
                  console.log(me.dataTable.gridData);
                  for (let j in me.dataTable.gridData) {
                    if (me.dataTable.gridData[j].id == user.id) {
                      me.currLeader = me.dataTable.gridData[j];
                    }
                  }
                }
              }
              me.dataTable.selected = templist;
            }
          }
        }
      );
    },
    onPage(event) {
      let me = this;
      me.dataTable.lazyParams = event;
      me.loadTable(null);
    },
  },
};
</script>

<style lang="scss" scoped>
//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: #12a25d;
  background: #12a25d;
}

::v-deep(.p-checkbox .p-checkbox-box:hover) {
  border-color: #12a25d;
}

::v-deep(.p-radiobutton .p-radiobutton-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background: #12a25d;
  border-color: #12a25d;
  color: #ffffff;
}
</style>
